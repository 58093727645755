/**
  checks whether a passed date is within the range of 2 other dates

  @param date  - Date instance
  @param startDate  - Date instance
  @param endDate  - Date instance
  @return - returns a boolean whether the passed date is within the range of the passed start- and endDate
  example:
  input:
  date = "Wed Sep 28 2022 14:37:05 GMT+0200 (Mitteleuropäische Sommerzeit)",
  startDate = "Wed Sep 25 2022 14:37:05 GMT+0200 (Mitteleuropäische Sommerzeit)",
  endDate = "Wed Sep 30 2022 14:37:05 GMT+0200 (Mitteleuropäische Sommerzeit)"
  result: true

 */

export default (date: Date, startDate: Date, endDate: Date) => {
  if (date >= (startDate as Date) && date < endDate) return true

  return false
}
