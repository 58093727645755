/**
  a helper to get the last friday depending on the passed date

  @param date  - Date instance or null
  @return - returns a an Object
  example:
  input: "Wed Sep 28 2022 14:37:05 GMT+0200 (Mitteleuropäische Sommerzeit)"
  result:{asDate: Fri Sep 23 2022 00:00:00 GMT+0200 (Mitteleuropäische Sommerzeit), asString: "23.09.2022"}

 */

import formatDateToLabel from './formatDateToLabel'

export default (date: Date | null) => {
  if (!date || isNaN(date.getHours())) return null

  const newDate = new Date(date)
  const isFriday = newDate.getDay()

  const lastFridayFromCurrentDay =
    isFriday === 5 ? newDate : new Date(newDate.setDate(newDate.getDate() - newDate.getDay() - 2))

  const formattedDate = formatDateToLabel(lastFridayFromCurrentDay)

  return {
    asDate: lastFridayFromCurrentDay,
    asString: formattedDate,
  }
}
