/**
  a helper to get the first day of a passed date

  @param date  - Date instance
  @return - returns a Date instance with the first day of the month
  example:
  input: "Wed Sep 28 2022 14:37:05 GMT+0200 (Mitteleuropäische Sommerzeit)"
  result: "Thu Sep 01 2022 00:00:00 GMT+0200 (Mitteleuropäische Sommerzeit)"

 */

export default (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), 1)
}
