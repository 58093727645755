import React from 'react'
import styled from 'styled-components'

import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { IconButton } from '@mui/material'

import Button from './Button'
import { StyledControlIconsContainer, StyledMeetingButton, StyledMeetingTitle } from './Meeting'

export interface RecommendedTicketProps {
  ticketTitle: string
  onEdit?: () => void
  isInactive?: boolean
  isEditOpen?: boolean
  isTicketId?: boolean
}

const RecommendedTicket: React.FC<RecommendedTicketProps> = ({
  ticketTitle,
  onEdit,
  isInactive,
  isEditOpen,
  isTicketId,
}) => {
  return (
    <StyledMeetingContainer>
      <StyledMeetingTitle style={{ width: '100%' }} data-ticketid={isTicketId}>
        {ticketTitle || 'No_Ticket_Number'}
      </StyledMeetingTitle>

      <StyledSubtitle style={isInactive ? { color: 'var(--text-primary-20)' } : {}}>
        {isTicketId ? 'BCS Ticket' : 'Jira Ticket'}
      </StyledSubtitle>
      {!isEditOpen ? (
        <Button
          style={isInactive ? { color: 'var(--text-primary-20)', padding: 0 } : { color: 'var(--primary)', padding: 0 }}
          variant="text"
          onClick={onEdit}
          disabled={isInactive}
          dataCy="create.ticket.booking"
        >
          Buchung anlegen
        </Button>
      ) : (
        <StyledControlIconsContainer>
          <IconButton disabled={isEditOpen} style={StyledMeetingButton}>
            <EditOutlinedIcon style={isEditOpen ? { color: 'var(--primary)' } : {}} />
          </IconButton>
          <IconButton disabled={isEditOpen} style={StyledMeetingButton}>
            <DeleteOutlinedIcon />
          </IconButton>
        </StyledControlIconsContainer>
      )}
    </StyledMeetingContainer>
  )
}

export default RecommendedTicket

const StyledMeetingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4px;
`

const StyledSubtitle = styled.span`
  color: var(--icons);
  opacity: 0.5;
`
