/**
  a mapper helper to format the current date into an own prefered representation of the date

  @param date  - Date instance
  @return - a string with a formatted date
  example:
  input: "Wed Sep 28 2022 14:23:02 GMT+0200 (Mitteleuropäische Sommerzeit)"
  result: "Mittwoch, 28. September 2022"

 */

type DateRecord = Record<number, string>

const Day: DateRecord = {
  0: 'Sonntag',
  1: 'Montag',
  2: 'Dienstag',
  3: 'Mittwoch',
  4: 'Donnerstag',
  5: 'Freitag',
  6: 'Samstag',
}

const Month: DateRecord = {
  0: 'Januar',
  1: 'Februar',
  2: 'März',
  3: 'April',
  4: 'Mai',
  5: 'Juni',
  6: 'Juli',
  7: 'August',
  8: 'September',
  9: 'Oktober',
  10: 'November',
  11: 'Dezember',
}

export default (date: Date) => {
  const weekDay = Day[date.getDay()]
  const day = `${date.getDate()}`.padStart(2, '0')
  const month = Month[date.getMonth()]
  const year = `${date.getFullYear()}`

  const label = `${weekDay}, ${day}. ${month} ${year}`
  return !label.includes('undefined') ? label : 'Datum auswählen'
}
