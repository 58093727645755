import React from 'react'

import MUIDialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from './Button'

interface DialogProps {
  open: boolean
  onAccept?: () => void
  acceptButtonTitle?: string
  declineButtonTitle?: string
  onDecline?: () => void
  title: string
  description?: string
  isAlert?: boolean
}

const Dialog: React.FC<DialogProps> = ({
  open,
  onAccept,
  acceptButtonTitle,
  onDecline,
  declineButtonTitle,
  title,
  description,
  isAlert: isALert = true,
}) => {
  return (
    <MUIDialog
      open={open}
      onClose={onDecline}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { minWidth: '400px' } }}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      {description && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button
          {...(isALert ? { variant: 'text' } : { variant: 'outlined', backgroundColor: '#fff' })}
          onClick={onDecline}
          dataCy="booking.overlay.keep"
        >
          {declineButtonTitle}
        </Button>
        <Button {...(isALert && { variant: 'text' })} onClick={onAccept} autoFocus dataCy="booking.overlay.delete">
          {acceptButtonTitle}
        </Button>
      </DialogActions>
    </MUIDialog>
  )
}

export default Dialog
