import React, { ChangeEvent } from 'react'
import { AutocompleteRenderInputParams, TextField as MUITextField } from '@mui/material'

export type Variants = 'filled' | 'outlined' | 'standard'
export type Sizes = 'medium' | 'small'

export interface TextFieldProps {
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  label?: React.ReactNode
  variant?: Variants
  size?: Sizes
  maxLength?: number
  fullWidth?: boolean
  name?: string
  error?: boolean
  helperText?: string
  type?: 'button' | 'search' | 'email' | 'text'
  defaultProps?: AutocompleteRenderInputParams
  autoComplete?: boolean
  dataCy?: string
}

const TextField: React.FC<TextFieldProps> = ({
  value,
  onChange,
  label,
  variant = 'standard',
  maxLength,
  size = 'medium',
  fullWidth = true,
  name,
  error,
  helperText,
  type = 'text',
  defaultProps,
  autoComplete = false,
  dataCy,
}) => {
  return (
    <MUITextField
      {...defaultProps}
      value={value}
      onChange={onChange}
      label={label}
      variant={variant}
      size={size}
      inputProps={{ maxLength }}
      fullWidth={fullWidth}
      name={name}
      error={error}
      helperText={helperText}
      multiline
      {...(autoComplete
        ? {
            InputProps: {
              ...defaultProps?.InputProps,
              type,
            },
          }
        : undefined)}
      data-cy={dataCy}
    />
  )
}

export default TextField
