/**
  defines the displayed string in the opener depending on the current time

  @return - a string depending on the current time
  example:
  currentDate: "Wed Sep 28 2022 14:23:02 GMT+0200 (Mitteleuropäische Sommerzeit)"
  result: "Hallo"

 */

export default () => {
  const currentDate = new Date()

  if (currentDate.getHours() < 12) {
    return 'Guten Morgen!'
  }
  if (currentDate.getHours() >= 12 && currentDate.getHours() < 18) {
    return 'Hallo!'
  } else {
    return 'Schönen Feierabend!'
  }
}
