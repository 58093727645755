import React from 'react'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import de from 'date-fns/locale/de'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker as MUIPicker } from '@mui/x-date-pickers/TimePicker'
import { TextField } from '@mui/material'

export interface TimePickerProps {
  label?: string
  value: Date | null
  onChange: (date: Date | null, keyboardInputValue?: string | undefined) => void
  minTime?: Date
  maxTime?: Date
  onError?: (error: string | null) => void
  maxWidth?: string
  fullWidth?: boolean
  hasIcon?: boolean
  datacy?: string
}

const TimePicker: React.FC<TimePickerProps> = ({
  label,
  value,
  onChange,
  minTime,
  maxTime = new Date(0, 0, 0, 23, 59),
  onError,
  maxWidth,
  fullWidth = true,
  hasIcon = true,
  datacy,
}) => (
  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
    <MUIPicker
      label={label}
      value={value}
      onChange={onChange}
      disableOpenPicker={!hasIcon}
      renderInput={(params) => {
        return (
          <TextField
            style={
              fullWidth
                ? { width: '100%' }
                : {
                    maxWidth,
                  }
            }
            variant="standard"
            {...params}
            data-cy={datacy}
          />
        )
      }}
      minTime={minTime}
      maxTime={maxTime}
      minutesStep={5}
      onError={onError}
    />
  </LocalizationProvider>
)

export default TimePicker
