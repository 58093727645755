/**
  a helper to create a list of all recommended events with the prefered fields

  @param events  - Array of all recommended received by the graphQL GET_ALL_EVENTS query
  @param date - A Date instance or null
  @return - returns an InputType[] with all the fields needed by the BookingsMask/Meetings
  example:
  input: too long to copy due to readibility <:
  result: [
    {
        "task": "Das Büro am Draht GmbH > Corporate Development > BADbooking / BADtime",
        "eventName": "BCS-Buchungsadapter | Daily",
        "duration": "2022-09-27T22:15:18.972Z",
        "date": "2022-09-28T13:16:17.815Z",
        "id": "1647970563848_JAppointment",
        "isEvent": true,
        "isTicket": false,
        "bookingType": "event"
    }
]

 */

import { InputType } from '../templates/BookingMask'
import { minutesToDate } from '.'
import subtractDurationBetweenDates from './subtractDurationBetweenDates'

export default (events, date: Date | null): InputType[] => {
  return events?.map((event) => {
    let previousName = ''

    const filteredTasks = event?.timesheetEntryProperties.pspPath.pspElement
      .map((element) => element.name)
      .filter((taskName: string) => {
        if (previousName !== taskName) {
          previousName = taskName
          return taskName
        }
      })

    const { startTime, endTime } = event?.timeRecordValues
    const duration = subtractDurationBetweenDates(startTime, endTime)

    const fullTaskTitle = [...filteredTasks, event?.timesheetEntryProperties?.task?.name].join(' > ')

    return {
      task: fullTaskTitle,
      description: event.comment,
      eventName: event.name,
      duration: minutesToDate(duration),
      date,
      id: event.bcsOid,
      isEvent: true,
      isTicket: false,
      bookingType: 'event',
    }
  })
}
