/**
  a helper to create a list of all bookings with the prefered fields

  @param records  - Array of all bookings received by the graphQL GET_ALL_BOOKINGS query
  @return - returns an InputType[] with all the fields needed by the BookingsMask/Meetings
  example:
  input: too long to copy due to readibility <:
  result: [
    {
        "task": "Das Büro am Draht GmbH > Corporate Development > BADbooking / BADtime",
        "taskId": "1647970125765_JTask",
        "description": null,
        "ticketNr": "",
        "duration": "2022-09-27T22:15:21.141Z",
        "date": "2022-09-28",
        "id": "1662559420866_JEffort",
        "isEvent": false,
        "isTicket": false,
        "bookingType": "task"
    }
]

 */

import { TimeRecord } from '../generated/bcsa-graphql.types'
import { InputType } from '../templates/BookingMask'
import { minutesToDate } from '.'

export default (records): InputType[] => {
  return records?.map((record: TimeRecord) => {
    let previousName = ''

    let filteredTasks: string[] = []
    let fullTaskTitle = ''
    let bookingType = ''
    let customTicketTitle = ''

    if (record.task) {
      filteredTasks = record.task.pspPath.pspElement
        .map((element) => element.name)
        .filter((taskName: string) => {
          if (previousName !== taskName) {
            previousName = taskName
            return taskName
          }
        })
      fullTaskTitle = [...filteredTasks, record?.task?.name].join(' > ')
      const effortAttr = record?.otherAttributes?.attribute?.find((attr) => attr.name === 'effortAttr1')
      customTicketTitle = effortAttr?.currentValue ?? ''
      bookingType = 'task'
    }
    if (record.ticket) {
      filteredTasks = record.ticket.pspPath.pspElement
        .map((element) => element.name)
        .filter((taskName: string) => {
          if (previousName !== taskName) {
            previousName = taskName
            return taskName
          }
        })
      fullTaskTitle = [...filteredTasks, record?.ticket?.task?.name].join(' > ')
      bookingType = 'ticket'
    }
    if (record.event) {
      filteredTasks = record.event.pspPath.pspElement
        .map((element) => element.name)
        .filter((taskName: string) => {
          if (previousName !== taskName) {
            previousName = taskName
            return taskName
          }
        })
      fullTaskTitle = [...filteredTasks, record?.event?.task?.name].join(' > ')
      bookingType = 'event'
    }

    return {
      task: fullTaskTitle,
      taskId: record.task?.bcsOid,
      description: record.comment,
      ticketNr: record.ticket?.name || customTicketTitle,
      ticketId: record.ticket?.bcsOid,
      eventName: record.event?.name,
      eventId: record.event?.bcsOid,
      duration: minutesToDate(record.expense),
      date: record.date,
      id: record.bcsOid,
      isEvent: false,
      isTicket: false,
      bookingType,
    }
  })
}
