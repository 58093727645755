import React, { useState } from 'react'
import styled from 'styled-components'

import { ClickAwayListener, IconButton, Typography } from '@mui/material'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import MenuIcon from '@mui/icons-material/Menu'

import UserMenu from './UserMenu'
interface HeaderProps {
  user: string
  handleLogout: () => void
}

const Header: React.FC<HeaderProps> = ({ user, handleLogout }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const isMenuOpen = Boolean(anchorEl)

  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false)
  const [isNavbarOpen, setIsNavbarOpen] = useState(false)
  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen)
  }

  const openUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const scrollToSection = (id: string) => {
    const elemId = document.getElementById(id)
    const offset = elemId?.offsetTop as number

    window.scrollTo({
      top: offset - 60,
      behavior: 'smooth',
    })
    setIsNavbarOpen(false)
  }

  return (
    <StyledHeaderContainer isNavbarOpen={isNavbarOpen}>
      <ClickAwayListener onClickAway={() => setIsNavbarOpen(false)}>
        <StyledFlexContainer>
          <StyledNavMenu>
            <MenuIcon onClick={toggleNavbar} />
          </StyledNavMenu>
          <StyledTitleContainer>
            <StyledTitle> {'< Zeit am Draht >'}</StyledTitle>
            {isNavbarOpen && (
              <StyledNavContainer>
                <StyledNavLabel onClick={() => scrollToSection('overview')}>Tagesübersicht</StyledNavLabel>
                <StyledNavLabel onClick={() => scrollToSection('bookingMask')}>Buchung anlegen</StyledNavLabel>
                <StyledNavLabel onClick={() => scrollToSection('closureDate')}>Buchungsabschluss</StyledNavLabel>
                <StyledNavLabel onClick={() => setIsLogoutDialogOpen(true)}>{user} abmelden</StyledNavLabel>
              </StyledNavContainer>
            )}
          </StyledTitleContainer>
          <StyledUserContainer>
            <IconButton
              style={{ color: 'var(--secondary)', padding: '8px 0' }}
              onClick={(event) => openUserMenu(event)}
            >
              <AccountCircleOutlinedIcon />
            </IconButton>
            <Typography variant="body1">{user}</Typography>
            <UserMenu
              anchorEl={anchorEl}
              isMenuOpen={isMenuOpen}
              onClose={handleClose}
              onAccept={handleLogout}
              handleLogoutDialog={{ isLogoutDialogOpen, setIsLogoutDialogOpen }}
            />
          </StyledUserContainer>
        </StyledFlexContainer>
      </ClickAwayListener>
    </StyledHeaderContainer>
  )
}

export default Header

const StyledHeaderContainer = styled.div<{ isNavbarOpen: boolean }>`
  box-sizing: border-box;
  background: #01166a;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: var(--secondary);
  padding: 10px 4px;
  // set absolute heights in order to make the transition work
  height: ${({ isNavbarOpen }) => (isNavbarOpen ? '230px' : '60px')};
  min-width: 380px;
  transition: all 200ms ease-in-out;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;

  @media (min-width: 480px) {
    padding: 10px 16px;
  }

  @media (min-width: 770px) {
    padding: 10px 30px;
    background-color: var(--primary-20);
    box-shadow: none;
    height: 60px;
    position: relative;
  }

  @media (min-width: 1180px) {
    padding: 10px 65px;
  }
`

const StyledFlexContainer = styled.div`
  display: flex;
  align-items: flex-start;

  @media (min-width: 770px) {
    justify-content: space-between;
    align-items: center;
  }
`

const StyledNavMenu = styled.div`
  display: block;
  height: 24px;
  margin-right: 35px;
  margin-top: 2px;

  @media (min-width: 770px) {
    display: none;
  }
`

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const StyledTitle = styled.span`
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.95rem;
`

const StyledNavContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (min-width: 770px) {
    display: none;
  }
`

const StyledNavLabel = styled.span`
  font-size: 20px;
  line-height: 32px;
  font-weight: 300;
`

const StyledUserContainer = styled.div`
  display: none;

  @media (min-width: 770px) {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`
