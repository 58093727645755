import React, { useEffect, useMemo, useState } from 'react'

import { CalendarPicker as MUICalendarPicker, CalendarPickerSkeleton, PickersDay } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import de from 'date-fns/locale/de'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { addOrSubtractYearsFromCurrentYear, dateWithinDateRange, getFirstDayOfMonth } from '../utils'

interface CalendarPickerProps {
  value: Date | null
  onChange: (date: Date | null, keyboardInputValue?: string | undefined) => void
  closureDate: Date
  isLoading?: boolean
  dataCy?: string
}

const currentDate = new Date()
const MIN_DATE = addOrSubtractYearsFromCurrentYear(currentDate, 2, 'subtract')
const MAX_DATE = addOrSubtractYearsFromCurrentYear(currentDate, 1, 'add')

const CalendarPicker: React.FC<CalendarPickerProps> = ({ value, onChange, dataCy, closureDate, isLoading }) => {
  const [updatedMonth, setUpdatedMonth] = useState(value)

  useEffect(() => {
    setUpdatedMonth(value)
  }, [closureDate])

  const firstDayOfSelectedMonth = useMemo(() => updatedMonth && getFirstDayOfMonth(updatedMonth), [value, updatedMonth])
  const isClosureDateDay = (date: Date) => dateWithinDateRange(date, firstDayOfSelectedMonth as Date, closureDate)

  //  if selected Date of the Overview Header is incorrect, the date in the calendarpicker is incorrect as well
  if (value && isNaN(value.getTime())) {
    return (
      <div style={{ textAlign: 'center' }}>
        <p>Fehlerhaftes Datum. </p>
      </div>
    )
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
      <MUICalendarPicker
        date={value}
        onChange={onChange}
        loading={isLoading}
        renderLoading={() => <CalendarPickerSkeleton />}
        data-cy={dataCy}
        minDate={MIN_DATE}
        maxDate={MAX_DATE}
        renderDay={(day, _value, DayComponentProps) => {
          const isSelected = DayComponentProps.selected
          const PickerDayStyles = {
            backgroundColor: isClosureDateDay(day) ? (!isSelected ? 'var(--primary' : '') : '',
            color: isClosureDateDay(day) ? (!isSelected ? 'white' : '') : '',
          }

          return <PickersDay {...DayComponentProps} style={PickerDayStyles} />
        }}
        onMonthChange={(month) => {
          setUpdatedMonth(month)
        }}
        onYearChange={(year) => {
          setUpdatedMonth(year)
        }}
      />
    </LocalizationProvider>
  )
}

export default CalendarPicker
