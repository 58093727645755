import React from 'react'
import styled from 'styled-components'

interface DailyOverViewFooterProps {
  isClosureDate: boolean | null
}

const DailyOverviewFooter: React.FC<DailyOverViewFooterProps> = ({ isClosureDate }) => {
  return (
    <StyledMeetingCardFooter>
      {isClosureDate && <StyledClosureDateLabel>Buchungsabschluss gesetzt</StyledClosureDateLabel>}
    </StyledMeetingCardFooter>
  )
}

export default DailyOverviewFooter

const StyledMeetingCardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 12px 16px;
  box-shadow: var(--elevation-meetingcard-header-top);
`

const StyledClosureDateLabel = styled.span`
  color: var(--success);
  line-height: 0.5;
  text-align: center;
`
