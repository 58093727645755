import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { useMutation } from '@apollo/client'
import {
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import Button from '../components/Button'
import CalendarPicker from '../components/CalendarPicker'
import DatePicker from '../components/DatePicker'
import Notification, { SnackbarMessage } from '../components/Notification'
import { dateToYear, formatDateToLabel, getLastDayOfPrevMonth, getLastFriday, getCurrentUser } from '../utils'
import { GET_CLOSURE_DATE, SET_CLOSURE_DATE } from '../graphql'

interface ClosureDateProps {
  value: Date | null
  onChange: (date: Date | null, keyboardInputValue?: string | undefined) => void
  goToCurrentDate: (date: Date) => void
  currentClosureDate: string
  isClosureDate: boolean | null
}

const ClosureDate: React.FC<ClosureDateProps> = ({
  value,
  onChange,
  goToCurrentDate,
  currentClosureDate,
  isClosureDate,
}) => {
  const [closureDateOption, setClosureDateOption] = React.useState('')
  const [customDate, setCustomDate] = useState(value)
  const [error, setError] = useState('')
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [notificationMessage, setNotificationMessage] = React.useState<readonly SnackbarMessage[]>([])

  useEffect(() => {
    setCustomDate(value)
  }, [value])

  const currentUser = getCurrentUser()

  const [setClosureDate, { called }] = useMutation(SET_CLOSURE_DATE, {
    refetchQueries: [
      {
        query: GET_CLOSURE_DATE,
        variables: {
          login: currentUser,
        },
      },
    ],
  })

  const lastFridayDate = useMemo(() => getLastFriday(value), [value])
  const lastDayFromMonthDate = useMemo(() => {
    const lastDayOfThisMonth = getLastDayOfPrevMonth(value, 1)
    if (formatDateToLabel(value) === lastDayOfThisMonth?.asString) {
      return lastDayOfThisMonth
    }
    return getLastDayOfPrevMonth(value)
  }, [value])

  // goToCurrentDate is necessary to force a rerender after a new closureDate is set. Afterwards the days inside the calendarpicker gettig evaluated once again and the color is added for the new closureDateDays. Otherwise the highlighting will miss unless a new rerender is triggered
  useEffect(() => {
    if (called && goToCurrentDate) {
      setTimeout(() => goToCurrentDate(new Date()), 500)
    }
  }, [currentClosureDate])

  useEffect(() => {
    if (customDate !== value) {
      setClosureDateOption('custom')
    }
  }, [customDate])

  const handleClose = () => {
    setIsDialogOpen(false)
    setError('')
    setClosureDateOption('')
  }

  const handleClosureDateOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClosureDateOption(event.target.value)
  }

  const handleCustomDateChange = (date: Date | null) => {
    if (date) {
      setCustomDate(date)
    }
  }

  const setBookingClosureDate = () => {
    if (!closureDateOption) {
      setError('Bitte wählen Sie eine Option aus')
      return
    }
    if (closureDateOption === 'weekly') {
      const isValidClosureDate = lastFridayDate && currentClosureDate < dateToYear(lastFridayDate.asDate)

      if (!isValidClosureDate) {
        setError(`Letzter Buchungsabschluss gesetzt am ${formatDateToLabel(new Date(currentClosureDate))}`)
        return
      }

      if (isValidClosureDate) {
        setError('')
        const formattedClosureDate = dateToYear(lastFridayDate.asDate)
        setClosureDate({ variables: { date: formattedClosureDate, login: currentUser } })
        setNotificationMessage((prev) => [
          ...prev,
          {
            message: `Buchungabschluss gesetzt zum: ${lastFridayDate.asString}!`,
            key: formattedClosureDate,
            color: 'notification-monthly',
          },
        ])
        setIsDialogOpen(false)
      }
    }
    if (closureDateOption === 'monthly') {
      const isValidClosureDate = lastDayFromMonthDate && currentClosureDate < dateToYear(lastDayFromMonthDate.asDate)

      if (!isValidClosureDate) {
        setError(`Letzter Buchungsabschluss gesetzt am ${formatDateToLabel(new Date(currentClosureDate))}`)
        return
      }

      if (isValidClosureDate) {
        setError('')
        const formattedClosureDate = dateToYear(lastDayFromMonthDate.asDate)
        setClosureDate({ variables: { date: formattedClosureDate, login: currentUser } })
        setNotificationMessage((prev) => [
          ...prev,
          {
            message: `Buchungabschluss gesetzt zum: ${lastDayFromMonthDate.asString}!`,
            key: formattedClosureDate,
            color: 'notification-monthly',
          },
        ])
        setIsDialogOpen(false)
      }
    }
    if (closureDateOption === 'custom') {
      if (customDate && isNaN(customDate?.getHours())) {
        setError('Das ausgewählte Datum ist ungültig!')
        return
      } else {
        setError('')
      }

      const isValidClosureDate = customDate && currentClosureDate < dateToYear(customDate)
      if (!isValidClosureDate) {
        setError(`Letzter Buchungsabschluss gesetzt am ${formatDateToLabel(new Date(currentClosureDate))}`)
        return
      }

      if (isValidClosureDate) {
        setError('')
        const formattedClosureDate = dateToYear(customDate)
        setClosureDate({ variables: { date: formattedClosureDate, login: currentUser } })
        setNotificationMessage((prev) => [
          ...prev,
          {
            message: `Buchungabschluss gesetzt zum ${formatDateToLabel(customDate)}!`,
            key: formattedClosureDate,
            color: 'notification-monthly',
          },
        ])
        setIsDialogOpen(false)
      }
    }
  }

  return (
    <StyledClosureDateContainer>
      <Notification
        handleMessage={{ snackPack: notificationMessage, setSnackPack: setNotificationMessage }}
        severity="info"
        autoHideDuration={3000}
      />
      <Dialog PaperProps={{ sx: { minWidth: '650px' } }} open={isDialogOpen} onClose={handleClose}>
        <DialogTitle>{'Buchungsabschluss setzen'}</DialogTitle>
        <DialogContent>
          <FormControl>
            <RadioGroup value={closureDateOption} onChange={handleClosureDateOptionChange}>
              <FormControlLabel
                value="weekly"
                control={<Radio />}
                label={`Wochenabschluss zum ${lastFridayDate?.asString}`}
              />
              <FormControlLabel
                value="monthly"
                control={<Radio />}
                label={`Monatsabschluss zum ${lastDayFromMonthDate?.asString}`}
              />
              <FormControlLabel
                value="custom"
                control={<Radio />}
                label={
                  <StyledCustomDateContainer>
                    <span style={{ fontSize: '1em' }}>Spezielles Abschlussdatum </span>
                    <DatePicker value={customDate} onChange={handleCustomDateChange} />
                  </StyledCustomDateContainer>
                }
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <StyledErrorContainer>
          <StyledErrorLabel>{error}</StyledErrorLabel>
        </StyledErrorContainer>
        <DialogActions>
          <Button variant="text" onClick={setBookingClosureDate}>
            Bestätigen
          </Button>
          <Button variant="text" onClick={handleClose} autoFocus>
            Verwerfen
          </Button>
        </DialogActions>
      </Dialog>
      <StyledCalendarPickerContainer id="closureDate">
        <StyledCalendarPickerContentContainer>
          <CalendarPicker
            value={value}
            onChange={onChange}
            closureDate={new Date(currentClosureDate)}
            isLoading={!currentClosureDate}
          />
        </StyledCalendarPickerContentContainer>
        <StyledNavigatorContainer>
          <Button style={{ padding: 0 }} variant="text" onClick={() => goToCurrentDate(new Date())}>
            <StyledButtonLabel>Heute</StyledButtonLabel>
          </Button>
        </StyledNavigatorContainer>
      </StyledCalendarPickerContainer>
      <StyledClosureDateButtonContainer style={!isClosureDate ? { background: 'var(--background-card)' } : {}}>
        <StyledButtonContainer>
          <StyledClosureDateInfoLabel style={!isClosureDate ? { color: 'var(--black' } : {}}>
            {currentClosureDate && `Letzter Buchungsabschluss: ${formatDateToLabel(new Date(currentClosureDate))}`}
          </StyledClosureDateInfoLabel>

          <Button
            endIcon={<ArrowForwardIcon />}
            variant="contained"
            onClick={() => setIsDialogOpen(true)}
            disabled={!!isClosureDate}
          >
            Buchungsabschluss setzen
          </Button>
        </StyledButtonContainer>
      </StyledClosureDateButtonContainer>
    </StyledClosureDateContainer>
  )
}

export default ClosureDate

const StyledClosureDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const StyledCustomDateContainer = styled.div`
  display: inline-flex;
  gap: 15px;
  align-items: center;
`

const StyledErrorContainer = styled.div`
  padding: 0 24px;
  height: 24px;
`
const StyledErrorLabel = styled.span`
  color: var(--error);
`

const StyledCalendarPickerContainer = styled(Card)`
  align-self: flex-start;
  && {
    background: var(--background-card);
    min-width: 320px;
  }
  // overridings of mui calendar specific styled to shrink the calendar a bit
  && .MuiCardContent-root {
    padding: 0;
  }
  && .MuiCalendarPicker-root,
  .MuiCalendarPicker-root > * {
    max-height: 320px;
    overflow: hidden;
  }

  && {
    @media (min-width: 320px) {
      width: 320px;
    }
  }
`
const StyledNavigatorContainer = styled.div`
  padding: 10px;
  text-align: end;
`

const StyledButtonLabel = styled.span`
  border-bottom: 1px solid var(--background);
  border-radius: 0;
  font-size: 14px;
  font-weight: 500;
`

const StyledCalendarPickerContentContainer = styled(CardContent)`
  padding-left: 0;
  padding-right: 0;
`

const StyledClosureDateButtonContainer = styled.div`
  background: var(--primary);
  padding: 12px 0 16px;
  border-radius: 4px;
  max-width: 320px;
  box-sizing: border-box;
`

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 24px;
`

const StyledClosureDateInfoLabel = styled.span`
  color: var(--white);
  margin-bottom: 12px;
`
