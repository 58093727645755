/**
  formats the passed date into a hours:mins string or return the amount of total minutes from the date

  @param date  - Date instance or null
  @return - returns an object with a hours:mins string and the amount of total minutes from the date as a number
  example:
  input: "Wed Sep 28 2022 00:15:55 GMT+0200 (Mitteleuropäische Sommerzeit)"
  result: {displayedTime: '00:15', totalMinutes: 15}

 */

export default (date: Date | null) => {
  // date can be `null` when e.g. `meetingData` from `Meeting.tsx` is corrupted,
  // which can happen, when duration of an _appointment_ spans multiple days.
  // in this case lets set the _appointment_ to a fixed 00:15 minutes duration.

  if (!(date instanceof Date)) {
    return {
      displayedTime: '08:00',
      totalMinutes: 480,
    }
  }

  const hours = `${date.getHours()}`.padStart(2, '0')
  const mins = `${date.getMinutes()}`.padStart(2, '0')
  return {
    displayedTime: `${hours}:${mins}`,
    totalMinutes: date.getHours() * 60 + date.getMinutes(),
  }
}
