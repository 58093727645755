/**
  a helper to transform an amount of minutes into a valid date

  @param minutes  - minutes as a number
  @return - returns a Date instance with the provided minutes set
  example:
  input: 15
  result: "Wed Sep 28 2022 00:15:10 GMT+0200 (Mitteleuropäische Sommerzeit)"

 */

export default (minutes: number) => {
  if (!minutes) return null

  const hours = minutes / 60
  const rhours = Math.floor(hours)
  const minute = (hours - rhours) * 60
  const rminutes = Math.round(minute)

  const date = new Date()
  date.setMinutes(rminutes)
  date.setHours(rhours)

  return date
}
