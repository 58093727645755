import React, { useState, useMemo, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { navigate, PageProps } from 'gatsby'

import { ApolloError, useMutation, useQuery } from '@apollo/client'
import { Card, CardContent, Skeleton } from '@mui/material'

import BookingMask, { InputType } from './BookingMask'
import DailyOverviewHeader from './DailyOverviewHeader'
import Meeting, { StyledMeetingContainer } from '../components/Meeting'
import Dialog from '../components/Dialog'
import Header from '../components/Header'
import Opener from '../components/Opener'
import ClosureDate from './ClosureDate'
import Notification, { SnackbarMessage } from '../components/Notification'
import { CREATE_EVENT_RECORD, DELETE_RECORD, GET_ALL_BOOKINGS, GET_ALL_TASKS, GET_CLOSURE_DATE } from '../graphql'
import {
  dateToYear,
  minutesToHoursAndMinutes,
  dateToTime,
  getAllBookings,
  getRecommendedEvents,
  getCurrentUser,
  logout,
  dateWithinDateRange,
  getFirstDayOfMonth,
} from '../utils'
import DailyOverviewFooter from './DailyOverviewFooter'

const HomePage: React.FC<PageProps> = () => {
  const [bookingResult, setBookingResult] = useState<InputType[]>([])
  const [currentBookingId, setCurrentBookingId] = useState('')
  const [editData, setEditData] = useState<InputType | undefined>(undefined)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [notificationMessage, setNotificationMessage] = React.useState<readonly SnackbarMessage[]>([])
  const [date, setDate] = React.useState<Date | null>(new Date())

  const handleLogout = () => {
    logout()
    navigate('/')
  }

  const user = getCurrentUser()
  useEffect(() => {
    if (!user) {
      handleLogout()
    }
  }, [user])

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setDate(date)
    }
  }

  const handleArrowDateChange = (direction: 'nextDay' | 'previousDay') => {
    if (date) {
      if (direction === 'nextDay') {
        const ne = new Date(date)
        ne?.setDate(ne.getDate() + 1)

        setDate(ne)
      } else {
        const ne = new Date(date)
        ne?.setDate(ne.getDate() - 1)

        setDate(ne)
      }
    }
  }

  const currentSelectedDate = useMemo(() => {
    return dateToYear(date as Date)
  }, [date])

  const { data: closureDateData, error: closureDateError } = useQuery(GET_CLOSURE_DATE, {
    variables: {
      login: user,
    },
  })

  const startDate = new Date(date as Date)
  startDate.setDate(startDate.getDate() - 1)
  startDate.setHours(0, 0, 0, 0)
  const endDate = new Date(date as Date)
  endDate.setHours(23, 59, 59, 999)

  const currentClosureDate = useMemo(() => closureDateData && closureDateData?.closureDate?.date, [closureDateData])
  const isClosureDate = useMemo(() => {
    const formattedDate = getFirstDayOfMonth(date as Date)
    const formatteClosureDate = new Date(currentClosureDate)
    formatteClosureDate.setHours(23)

    return date && dateWithinDateRange(date, formattedDate, formatteClosureDate)
  }, [date, currentClosureDate])

  const { data: allTasks, error: allTasksError } = useQuery(GET_ALL_TASKS, {
    variables: { login: user, date: currentSelectedDate },
    fetchPolicy: 'network-only',
  })

  const events = allTasks?.timesheet?.timesheetEntries?.event
  const recommendedEvents = useMemo(() => getRecommendedEvents(events, date), [allTasks])

  const {
    data: allBookings,
    refetch,
    loading,
    error: allBookingsError,
  } = useQuery(GET_ALL_BOOKINGS, {
    variables: { start: currentSelectedDate, end: currentSelectedDate },
    fetchPolicy: 'network-only',
  })

  const timeRecord = allBookings?.timeRecords?.timeRecord
  const bookings: InputType[] = useMemo(() => getAllBookings(timeRecord), [timeRecord])

  const filterRecords = useCallback(
    (records: InputType[], type: 'event' | 'ticket') => {
      return records?.filter((record) =>
        bookings?.every((booking) => {
          return type === 'event' ? booking.eventName !== record.eventName : booking.ticketId !== record.id
        }),
      )
    },
    [bookings],
  )

  const filteredEvents = useMemo(() => {
    return filterRecords(recommendedEvents, 'event')
  }, [recommendedEvents, bookings])

  const totalBookingTime = useMemo(() => {
    if (bookings) {
      const totalMinutes = bookings.reduce((prevBooking, currentBooking) => {
        return prevBooking + dateToTime(currentBooking.duration).totalMinutes
      }, 0)

      return minutesToHoursAndMinutes(totalMinutes)
    } else {
      return '00:00'
    }
  }, [bookings])

  const [deleteTimeRecord] = useMutation(DELETE_RECORD, {
    variables: {
      effortId: currentBookingId,
    },
  })

  const deleteRecord = async () => {
    try {
      await deleteTimeRecord()
      setNotificationMessage((prev) => [
        ...prev,
        { message: 'Buchung gelöscht!', key: currentBookingId, color: 'primary' },
      ])
      refetch()
    } catch (e) {
      const errors = e as ApolloError
      setNotificationMessage((prev) => [
        ...prev,
        { message: errors.graphQLErrors[0].message, key: currentBookingId, color: 'error' },
      ])
    }
  }

  const onDelete = (id: string) => {
    setCurrentBookingId(id)
    setIsDeleteDialogOpen(true)
  }

  const onDialogDecline = () => {
    setIsDeleteDialogOpen(false)
  }

  const onDialogAccept = () => {
    deleteRecord()
    setIsDeleteDialogOpen(false)
  }

  const onEdit = (id: string) => {
    const currentBookingResult = bookings.find((booking) => booking.id === id)
    setEditData(currentBookingResult)
  }

  const duplicate = async (id: string) => {
    const currentBookingResult = bookings.find((booking) => booking.id === id)

    setEditData(() => ({
      ...(currentBookingResult as InputType),
      isDuplicate: true,
    }))
  }

  const [createEventRecord, { data }] = useMutation(CREATE_EVENT_RECORD)

  const onAccept = async (id: string) => {
    const currentBookingResult = recommendedEvents.find((event) => event.id === id)
    if (currentBookingResult) {
      try {
        await createEventRecord({
          variables: {
            expense: dateToTime(currentBookingResult.duration).totalMinutes,
            date: currentSelectedDate,
            comment: currentBookingResult.description || currentBookingResult.eventName,
            eventId: currentBookingResult.id,
          },
        })
        setNotificationMessage((prev) => [
          ...prev,
          { message: 'Buchung angelegt!', key: currentBookingId, color: 'notification-daily' },
        ])
      } catch (e) {
        const errors = e as ApolloError
        setNotificationMessage((prev) => [
          ...prev,
          { message: errors.graphQLErrors[0].message, key: currentBookingId, color: 'error' },
        ])
      }
    }
  }

  // check if the user is authorized, otherwise log him out and redirect to the login page
  useEffect(() => {
    if (
      closureDateError?.networkError?.name.includes('Server') ||
      allBookingsError?.networkError?.name.includes('Server') ||
      allTasksError?.networkError?.name.includes('Server')
    ) {
      handleLogout()
    }
  }, [closureDateError, allBookingsError, allTasksError])

  useEffect(() => {
    if (data) refetch()
  }, [data])

  return (
    <>
      <Notification
        handleMessage={{ snackPack: notificationMessage, setSnackPack: setNotificationMessage }}
        severity="info"
        autoHideDuration={3000}
      />
      <Dialog
        title="Diese Buchung löschen?"
        description="Diese Buchung wird unwiderruflich gelöscht."
        open={isDeleteDialogOpen}
        onAccept={onDialogAccept}
        acceptButtonTitle="Löschen"
        onDecline={onDialogDecline}
        declineButtonTitle="Behalten"
      />
      <StyledContainer>
        <Header user={user as string} handleLogout={handleLogout} />
        <StyledOpenerContainer>
          <Opener date={date as Date} handleArrowDateChange={handleArrowDateChange} />
        </StyledOpenerContainer>
        <StyledContentContainer>
          <StyledMeetingCard id="overview">
            <DailyOverviewHeader
              date={date}
              setDate={handleDateChange}
              totalBookingTime={totalBookingTime}
              isClosureDate={isClosureDate}
            />
            <StyledCardContent data-cy="card.container">
              {loading &&
                !filteredEvents &&
                !bookings &&
                Array.from(new Array(10), (_, index) => (
                  <StyledMeetingContainer data-cy={`skeleton.${index}`} key={index}>
                    <StyledCardContent>
                      <Skeleton
                        animation="wave"
                        height={20}
                        width="20%"
                        sx={{ bgcolor: 'text.disabled' }}
                        style={{ marginTop: 15 }}
                      />
                      <Skeleton animation="wave" height={20} width="80%" style={{ marginTop: 15, marginBottom: 30 }} />
                    </StyledCardContent>
                  </StyledMeetingContainer>
                ))}
              {bookings &&
                !loading &&
                // newest bookings should appear on top
                bookings
                  .map((booking) => (
                    <Meeting
                      key={booking.id}
                      meetingData={booking}
                      isEditOpen={editData?.id === booking.id}
                      isInactive={Boolean(editData?.id && editData?.id !== booking.id)}
                      onEdit={onEdit}
                      onDelete={onDelete}
                      isClosureDate={isClosureDate}
                      dataCy={`card.${booking.id}`}
                      duplicateBooking={duplicate}
                      isDuplicateBookingOpen={editData?.id === booking.id}
                    />
                  ))
                  .reverse()}
              {filteredEvents &&
                !isClosureDate &&
                !loading &&
                // newest bookings should appear on top
                filteredEvents
                  .map((event) => (
                    <Meeting
                      key={event.id}
                      meetingData={event}
                      isEditOpen={editData?.id === event.id}
                      isInactive={Boolean(editData?.id && editData?.id !== event.id)}
                      onAccept={onAccept}
                      isClosureDate={isClosureDate}
                      dataCy={`card.${event.id}`}
                    />
                  ))
                  .reverse()}
            </StyledCardContent>
            <DailyOverviewFooter isClosureDate={isClosureDate} />
          </StyledMeetingCard>
          <BookingMask
            createBooking={{ bookingResult, setBookingResult }}
            editMeeting={{ editData, setEditData }}
            bookingDate={currentSelectedDate}
            refetchBookings={refetch}
            isClosureDate={isClosureDate}
            currentSelectedDate={date}
          />
          <ClosureDate
            value={date}
            onChange={handleDateChange}
            goToCurrentDate={handleDateChange}
            currentClosureDate={currentClosureDate}
            isClosureDate={isClosureDate}
          />
        </StyledContentContainer>
      </StyledContainer>
    </>
  )
}

export default HomePage

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`
const StyledOpenerContainer = styled.div`
  color: var(--white);
  padding: 20px 65px 0;
  display: none;

  @media (min-width: 1440px) {
    display: block;
  }
`

const StyledContentContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  grid-template-rows: minmax(auto, 650px);
  gap: 20px;
  padding: 80px 4px;

  @media (min-width: 480px) {
    padding: 80px 16px;
  }

  @media (min-width: 770px) {
    padding: 20px 30px;
  }

  @media (min-width: 1180px) {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 20px 65px;
    height: 100%;
  }

  @media (min-width: 1440px) {
    height: calc(100% - 215px);
  }
`

const StyledMeetingCard = styled(Card)`
  && {
    background: var(--background-card);
    min-width: 350px;
    height: 100%;
  }

  @media screen and (min-width: 820px) {
    flex: 1;
  }
`

const StyledCardContent = styled(CardContent)`
  && {
    height: calc(100% - 102px);
    overflow-y: auto;
    padding-top: 0;
    padding-bottom: 0;
  }
`
