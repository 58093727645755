import React, { useState, useEffect, useMemo, SyntheticEvent } from 'react'
import styled, { css, keyframes } from 'styled-components'

import { Card, CardContent, FormControlLabel, Switch } from '@mui/material'
import StarIcon from '@mui/icons-material/Star'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import { useQuery, useMutation, ApolloError } from '@apollo/client'

import Autocomplete from '../components/AutoComplete'
import Button from '../components/Button'
import TextField from '../components/TextField'
import TimePicker from '../components/TimePicker'
import Notification, { SnackbarMessage } from '../components/Notification'
import FavoritesDialog from '../components/FavoritesDialog'

import { favoritesAtom } from '../store'

import { dateToTime } from '../utils'
import { GET_ALL_TASKS, CREATE_TASK_RECORD, UPDATE_TASK_RECORD, UPDATE_EVENT_RECORD } from '../graphql'
import { PspElement } from '../generated/bcsa-graphql.types'
import { useAtom } from 'jotai'

// created by usage not real data
export type Task = {
  name: string
  timesheetEntryProperties: {
    pspPath: {
      pspElement: {
        name: string
        bcsOid: string
      }[]
    }
  }
  bcsOid: string
}

export type BookingTask = {
  label: string
  value: string
  description: string
  duration?: Date
}

export type Ticket = BookingTask & {
  formattedLabel: string
  taskId: string
}

export type InputType = {
  task: string | null
  taskId?: string
  description: string
  ticketNr?: string
  eventName?: string
  eventId?: string
  duration: Date
  date: Date
  id: string
  isEvent: boolean
  isTicket: boolean
  bookingType: 'task' | 'ticket' | 'event'
  isDuplicate?: boolean
  isFavorite: boolean
}

type ErrorTypes = {
  task: string
  description: string
  duration: string | undefined
}

export const InitialFormValues: InputType = {
  task: null,
  taskId: '',
  description: '',
  ticketNr: '',
  eventName: '',
  eventId: '',
  duration: new Date('2014-08-18T00:15'),
  date: new Date(),
  id: '',
  isEvent: false,
  isTicket: false,
  bookingType: 'task',
  isDuplicate: false,
  isFavorite: false,
}

const MIN_TIME = new Date(0, 0, 0, 0, 15)

export interface BookingMaskProps {
  title?: string
  createBooking: {
    bookingResult?: InputType[]
    setBookingResult: React.Dispatch<React.SetStateAction<InputType[]>>
  }
  editMeeting?: {
    editData?: InputType
    setEditData: React.Dispatch<React.SetStateAction<InputType | undefined>>
  }
  bookingDate: string
  refetchBookings: () => void
  isClosureDate: boolean | null
  currentSelectedDate: Date | null
}

const BookingMask: React.FC<BookingMaskProps> = ({
  title = 'Neue Buchung anlegen',
  createBooking,
  editMeeting,
  bookingDate,
  refetchBookings,
  isClosureDate,
  currentSelectedDate,
}) => {
  // check if window object is available in order to prevent crash due to ssr where the object is not available.
  const isBrowser = typeof window !== `undefined`
  if (!isBrowser) return null

  const { setBookingResult } = createBooking
  const [notificationMessage, setNotificationMessage] = React.useState<readonly SnackbarMessage[]>([])
  const [isFavoritesDialogOpen, setIsFavoritesDialogOpen] = useState(false)
  const [bookingData, setBookingData] = useState<InputType>(InitialFormValues)
  const [errors, setErrors] = useState<ErrorTypes>({
    task: '',
    description: '',
    duration: '',
  })
  const [taskId, setTaskId] = useState<string | undefined>(undefined)
  const [isBookingTaskDisabled, setIsBookingTaskDisabled] = useState<boolean>(false)
  const [taskInputValue, setTaskInputValue] = useState('')
  const [ticketInputValue, setTicketInputValue] = useState('')
  const [isProcessing, setProccessing] = useState(false)

  const [isStarred, setIsStarred] = useState(false)

  const [favorites, setFavorites] = useAtom(favoritesAtom)

  useEffect(() => {
    if (bookingData.task) {
      setErrors((prevError) => ({
        ...prevError,
        task: '',
      }))
    }
    if (bookingData.description) {
      setErrors((prevError) => ({
        ...prevError,
        description: '',
      }))
    }
  }, [bookingData])

  useEffect(() => {
    if (editMeeting?.editData) {
      // ToDo: check if this condition is not needed anymore
      if (editMeeting?.editData.ticketNr) {
        setBookingData({
          ...editMeeting.editData,
          ticketNr: editMeeting?.editData.ticketNr,
        })

        setTicketInputValue(editMeeting?.editData.ticketNr)
      } else {
        setBookingData(editMeeting.editData)
      }
    }
  }, [editMeeting?.editData])

  const handleTicketInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTicketInputValue(event.target.value)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    setBookingData((prevBookingData) => ({
      ...prevBookingData,
      [name]: value,
    }))
  }

  const nullTask: BookingTask = {
    label: '',
    value: '',
    description: '',
  }

  const searchTaskByLabel = (label: string | null): BookingTask => {
    let task = bookingTasks.find((task) => {
      if (task.label === label) {
        return task
      }
    })

    if (!task) {
      task = nullTask
    }

    return task
  }

  const handleTaskSearch = (value: string | null) => {
    if (!bookingTasks) {
      return
    }

    const currentBookingTask = searchTaskByLabel(value)

    if (value === null) {
      console.log('BookingMask:handleTaskSearch() - reset star')
      setIsStarred(false)
    }

    setTaskId(currentBookingTask?.value || '')

    const currentFavorite = searchFavoriteByValue(currentBookingTask?.value)[0]

    setBookingData((prevBookingData) => ({
      ...prevBookingData,
      task: currentBookingTask?.label ? currentBookingTask.label : null,
      taskId: currentBookingTask?.value || 'YOU_SHOULD_NOT_SEE_THIS_JTask',
      description: currentFavorite?.description || bookingData.description || '',
      duration: currentFavorite?.duration ? new Date(currentFavorite?.duration) : bookingData.duration,
    }))
  }

  const handleDurationChange = (value: Date | null) => {
    if (value) {
      setBookingData((prevBookingData) => ({
        ...prevBookingData,
        duration: value,
      }))
    }
  }

  const searchFavoriteByValue = (value: string | null): BookingTask[] => {
    return favorites.filter((e: BookingTask) => e.value === value)
  }

  const handleFavoriteStateChange = async (event: SyntheticEvent<Element, Event>, checked: boolean) => {
    const valid = checked && !!bookingData.task

    setIsStarred(valid) // internal state

    setBookingData((prevBooking) => ({ ...prevBooking, isFavorite: valid }))

    const f: BookingTask = {
      label: bookingData.task ? bookingData.task : '',
      value: bookingData.taskId ? bookingData.taskId : '',
      description: bookingData.description,
      duration: bookingData.duration,
    }

    const favs: BookingTask[] = favorites.filter((e: BookingTask) => e.value !== f.value)

    if (valid) {
      favs.push(f)
    }

    setFavorites(favs)
  }

  const onCreateBooking = async () => {
    setProccessing(true)

    const initialErrors: ErrorTypes = {
      task: '',
      description: '',
      duration: errors.duration,
    }

    if (!bookingData.task) {
      initialErrors.task = 'Bitte wählen Sie einen Buchungspunkt aus.'
    }

    if (!bookingData.description && !ticketInputValue) {
      initialErrors.description = 'Bitte füllen Sie die Buchungs-Beschreibung aus.'
    }

    const isValid = Object.values(initialErrors).every((error) => !error)

    if (isValid) {
      if (editMeeting?.editData) {
        const currentBookingTask = bookingTasks.find((task) => {
          if (task.label === bookingData.task) {
            return task
          }
        })
        setTaskId(currentBookingTask?.value || '')
        if (bookingData.bookingType === 'event') {
          try {
            await updateEventRecord({
              variables: {
                expense: dateToTime(bookingData.duration).totalMinutes,
                date: bookingDate,
                comment: bookingData.description,
                eventId: bookingData.eventId,
                effortId: bookingData.id,
              },
            })
            setNotificationMessage((prev) => [
              ...prev,
              { message: 'Buchung editiert!', key: bookingData.id, color: 'notification-daily' },
            ])
          } catch (e) {
            const errors = e as ApolloError
            setNotificationMessage((prev) => [
              ...prev,
              { message: errors.graphQLErrors[0].message, key: bookingData.id, color: 'error' },
            ])
          }
        } else if (bookingData.isDuplicate) {
          try {
            await createTaskRecord({
              variables: {
                expense: dateToTime(bookingData.duration).totalMinutes,
                date: bookingDate,
                comment: bookingData.description,
                taskId: bookingData.taskId, // this may be the cause
                ticketIdMSI: ticketInputValue,
              },
            })
            setNotificationMessage((prev) => [
              ...prev,
              { message: 'Buchung angelegt!', key: bookingData.id, color: 'notification-daily' },
            ])
          } catch (e) {
            const errors = e as ApolloError
            setNotificationMessage((prev) => [
              ...prev,
              { message: errors.graphQLErrors[0].message, key: bookingData.id, color: 'error' },
            ])
          }
        } else {
          try {
            await updateTaskRecord({
              variables: {
                expense: dateToTime(bookingData.duration).totalMinutes,
                date: bookingDate,
                comment: bookingData.description,
                taskId: currentBookingTask?.value,
                effortId: bookingData.id,
                ticketIdMSI: ticketInputValue,
              },
            })
            setNotificationMessage((prev) => [
              ...prev,
              { message: 'Buchung editiert!', key: bookingData.id, color: 'notification-daily' },
            ])
          } catch (e) {
            const errors = e as ApolloError
            setNotificationMessage((prev) => [
              ...prev,
              { message: errors.graphQLErrors[0].message, key: bookingData.id, color: 'error' },
            ])
          }
        }

        editMeeting.setEditData(undefined)
      } else {
        setBookingResult((prevBookingResult) => [
          ...prevBookingResult,
          {
            ...bookingData,
          },
        ])
        try {
          await createTaskRecord({
            variables: {
              expense: dateToTime(bookingData.duration).totalMinutes,
              date: bookingDate,
              comment: bookingData.description,
              taskId,
              ticketIdMSI: ticketInputValue,
            },
          })
          setNotificationMessage((prev) => [
            ...prev,
            { message: 'Buchung angelegt!', key: bookingData.id, color: 'notification-daily' },
          ])
        } catch (e) {
          const errors = e as ApolloError
          setNotificationMessage((prev) => [
            ...prev,
            { message: errors.graphQLErrors[0].message, key: bookingData.id, color: 'error' },
          ])
        }
      }
      setBookingData(InitialFormValues)
      setTicketInputValue('')
    }

    setIsStarred(false)
    setProccessing(false)
    setErrors(initialErrors)
  }

  const onCancel = () => {
    setBookingData(InitialFormValues)
    setTicketInputValue('')
    if (editMeeting?.setEditData) {
      editMeeting.setEditData(undefined)
    }
    setIsStarred(false)
  }

  const [createTaskRecord, { data: createTaskSuccess }] = useMutation(CREATE_TASK_RECORD)
  const [updateTaskRecord, { data: updateTaskSuccess }] = useMutation(UPDATE_TASK_RECORD)
  const [updateEventRecord, { data: updateEventSuccess }] = useMutation(UPDATE_EVENT_RECORD)

  useEffect(() => {
    if (createTaskSuccess || updateTaskSuccess || updateEventSuccess) {
      refetchBookings()
    }
  }, [createTaskSuccess, updateTaskSuccess, updateEventSuccess])

  const startDate = new Date(currentSelectedDate as Date)
  startDate.setDate(startDate.getDate() - 1)
  startDate.setHours(0, 0, 0, 0)
  const endDate = new Date(currentSelectedDate as Date)
  endDate.setHours(23, 59, 59, 999)

  useEffect(() => {
    if (bookingData?.ticketNr && bookingTasks && taskId) {
      bookingTasks.find((task) => {
        if (task.value === taskId) {
          setBookingData((prevBooking) => ({ ...prevBooking, task: task.label }))
          setIsBookingTaskDisabled(true)
          return
        }
      })
    }
    if (!bookingData.ticketNr) {
      setIsBookingTaskDisabled(false)
    }
  }, [bookingData.ticketNr])

  //ToDo: Type Queries!
  const { loading, data } = useQuery(GET_ALL_TASKS, {
    variables: { login: process.env.BCS_TEST_USER, date: bookingDate },
    fetchPolicy: 'network-only',
  })

  const tasks: Task[] = data?.timesheet?.timesheetEntries?.task
  const bookingTasks: BookingTask[] = useMemo(
    () =>
      tasks &&
      tasks.map((task) => {
        let previousName = ''
        const filteredTasks = task?.timesheetEntryProperties.pspPath.pspElement
          .map((element: PspElement) => element.name)
          .filter((taskName: string) => {
            if (previousName !== taskName) {
              previousName = taskName
              return taskName
            }
          })

        const fullTaskTitle = [...filteredTasks, task.name].join(' > ')

        return {
          label: fullTaskTitle,
          value: task.bcsOid,
          description: '',
        }
      }),
    [tasks],
  )

  const bookingMaskTitle = useMemo(() => {
    if (editMeeting?.editData?.isDuplicate) {
      return 'Buchung duplizieren'
    }
    if (editMeeting?.editData) {
      return 'Buchung ändern'
    } else {
      return title
    }
  }, [editMeeting?.editData])

  const cta = useMemo(() => {
    if (editMeeting?.editData && editMeeting.editData.isTicket) {
      return {
        label: 'buchung anlegen',
        dataCy: 'booking.button.create',
      }
    }
    if (editMeeting?.editData && editMeeting.editData.isDuplicate) {
      return {
        label: 'Buchung duplizieren',
        dataCy: 'booking.button.create',
      }
    }
    if (editMeeting?.editData && !editMeeting.editData.isTicket) {
      return {
        label: 'Änderungen speichern',
        dataCy: 'booking.button.edit',
      }
    } else
      return {
        label: 'buchung anlegen',
        dataCy: 'booking.button.create',
      }
  }, [editMeeting?.editData])

  const editNotice = editMeeting?.editData?.isDuplicate
    ? 'Um das Duplikat zu verschieben, bitte im Kalender den entsprechenden Tag auswählen oder mit den Pfeiltasten navigieren. Die ursprüngliche Buchung bleibt bestehen.'
    : 'Um die Buchung zu verschieben, bitte im Kalender den entsprechenden Tag auswählen oder mit den Pfeiltasten navigieren.'

  const notifyFavoriteChange = (notify = true) => {
    if (notify === false) {
      return
    }

    if (favorites.length > 0) {
      setNotificationMessage((prev) => [
        ...prev,
        { message: 'Favoriten geändert!', key: bookingData.id, color: 'favorite' },
      ])
    } else {
      setNotificationMessage((prev) => [
        ...prev,
        { message: 'Favoriten gelöscht!', key: bookingData.id, color: 'favorite' },
      ])
    }
  }

  const Favorites = () => {
    if (favorites.length === 0) {
      return (
        <Button style={{ padding: 0 }} variant="text" onClick={() => setIsFavoritesDialogOpen(true)}>
          <StyledFavoriteButtonLabel>Favoriten</StyledFavoriteButtonLabel>
        </Button>
      )
    } else {
      return (
        <StyledFavoriteContainer>
          {favorites.map((favorite, index) => (
            <StyledFavorite key={favorite.value}>
              <StarIcon style={{ color: 'var(--favorite)' }} />
              <Button
                variant="text"
                onClick={() => handleTaskSearch(favorite.label)}
                style={{ padding: 0, color: 'var(--text-primary)' }}
              >
                {favorite.label.split('>').pop()?.toUpperCase()}
              </Button>
              {/* display manage favorite button always behind the last element in the list */}
              {index + 1 === favorites.length && (
                <AddCircleOutlineOutlinedIcon
                  onClick={() => setIsFavoritesDialogOpen(true)}
                  style={{ color: 'var(--mui-icons)', paddingLeft: '11px', cursor: 'pointer' }}
                />
              )}
            </StyledFavorite>
          ))}
        </StyledFavoriteContainer>
      )
    }
  }

  const bookingDurationHelper = (minutesToAdd: number): Date => {
    /**
     * returns a date object based on the `bookingData.duration` with
     * `minutesToAdd` added to its `minutes`.
     */
    const ts: number = bookingData.duration.getTime()
    return new Date(ts + minutesToAdd * 60 * 1000)
  }

  const onAcceptFavorites = (favorites: BookingTask[]) => {
    setIsFavoritesDialogOpen(false)
    setFavorites(favorites)
    notifyFavoriteChange()
  }

  return (
    <>
      <Notification
        handleMessage={{ snackPack: notificationMessage, setSnackPack: setNotificationMessage }}
        severity="info"
        autoHideDuration={3000}
      />
      <FavoritesDialog
        isDialogOpen={isFavoritesDialogOpen}
        onAccept={onAcceptFavorites}
        onDecline={() => setIsFavoritesDialogOpen(false)}
        favoriteOptions={bookingTasks}
        favorites={favorites}
      />
      <StyledBookingMaskCard id="bookingMask" data-edit={Boolean(editMeeting && editMeeting.editData)}>
        <StyledCardContent>
          <StyledHeadline data-edit={!!editMeeting?.editData}>{bookingMaskTitle}</StyledHeadline>
          <StyledInputRow>
            <StyledInputContainer>
              <Favorites />
            </StyledInputContainer>
          </StyledInputRow>
          <StyledInputRow>
            <StyledInputContainer>
              <Autocomplete
                freeSolo={false}
                disableClearable={false}
                disabled={
                  !!(editMeeting?.editData && editMeeting?.editData?.bookingType !== 'task') || isBookingTaskDisabled
                }
                loading={loading}
                loadingText="Lade Buchungspunkte..."
                value={bookingData.task || ''}
                inputValue={taskInputValue}
                onInputChange={(e, value) => {
                  setTaskInputValue(value)
                }}
                onChange={(e, value) => {
                  handleTaskSearch(value)
                }}
                options={bookingTasks ? bookingTasks.map((task) => task.label).sort() : []}
                label="Buchungspunkt auswählen"
                type="text"
                error={!!errors.task}
                helperText={errors.task}
                dataCy={`booking.point.autocomplete${taskId ? `.${taskId}` : ''}`}
              />
            </StyledInputContainer>
          </StyledInputRow>
          <StyledInputRow>
            <StyledInputContainer>
              <TimePicker
                label="Dauer"
                value={bookingData.duration}
                onChange={handleDurationChange}
                minTime={MIN_TIME}
                onError={(e) => {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    duration: e || '',
                  }))
                }}
                datacy="booking.duration.input"
                maxWidth="100px"
                fullWidth={false}
              />
            </StyledInputContainer>
            <StyledButtonContainer>
              <Button
                onClick={() => {
                  setBookingData({ ...bookingData, duration: bookingDurationHelper(-15) })
                }}
              >
                -15m
              </Button>
              <Button
                onClick={() => {
                  setBookingData({ ...bookingData, duration: bookingDurationHelper(15) })
                }}
              >
                +15m
              </Button>
            </StyledButtonContainer>
          </StyledInputRow>
          <StyledInputRow>
            <StyledInputContainer>
              <TextField
                value={bookingData.description}
                onChange={handleInputChange}
                label="Buchungs-Beschreibung"
                size="medium"
                maxLength={500}
                fullWidth
                name="description"
                error={!!errors.description}
                helperText={errors.description}
                dataCy="booking.description.input"
              />
            </StyledInputContainer>
          </StyledInputRow>
          <StyledInputRow>
            <StyledInputContainer>
              <TextField
                value={ticketInputValue}
                onChange={handleTicketInputChange}
                label="Ticket Nr. (optional)"
                size="medium"
                maxLength={25}
                fullWidth
                name="ticket"
                dataCy="booking.ticket.input"
              />
            </StyledInputContainer>
          </StyledInputRow>
          <StyledInputRow>
            {/*TODO: think about moving the state of `isStarred` to parent component*/}
            <FormControlLabel
              control={<Switch checked={isStarred && !!bookingData.task} />}
              label="Als Favorit speichern"
              onChange={handleFavoriteStateChange}
            />
          </StyledInputRow>
          {editMeeting?.editData && <StyledNotice>{editNotice}</StyledNotice>}

          <StyledButtonContainer>
            <Button
              onClick={onCreateBooking}
              dataCy={cta.dataCy}
              style={editMeeting?.editData ? { backgroundColor: 'var(--primary)' } : {}}
              disabled={!!isClosureDate || isProcessing}
            >
              {cta.label}
            </Button>
            <Button
              onClick={onCancel}
              variant="outlined"
              dataCy="booking.button.cancel"
              style={
                editMeeting?.editData
                  ? { border: '1px solid var(--primary)', backgroundColor: '#fff', color: 'var(--primary)' }
                  : { backgroundColor: '#fff' }
              }
              disabled={!!isClosureDate}
            >
              Abbrechen
            </Button>
          </StyledButtonContainer>
        </StyledCardContent>
      </StyledBookingMaskCard>
    </>
  )
}

export default BookingMask

const editAnimation = keyframes`
0% {
    transform: scale(1);
  }
70% {
  transform: scale(1.02);
}
100% {
  transform: scale(1);
}
`
// Animations cannot be interpolated therefor the css function is necessary
const editStylesMixin = css`
  animation: ${editAnimation} 500ms;
  background: var(--white);
  box-shadow: var(--elevation-highlight-darkmode);
`

const StyledBookingMaskCard = styled(Card)<{ 'data-edit': boolean }>`
  && {
    background: var(--background-card);
    ${(props) => (props['data-edit'] ? editStylesMixin : undefined)}
    min-width: 360px;
  }

  @media (min-width: 820px) {
    flex: 2;
  }
`

const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 24px 24px;
`

const StyledHeadline = styled.h3<{ 'data-edit': boolean }>`
  margin: 0;
  ${(props) => (props['data-edit'] ? `color: var(--primary)` : '')}
`

const StyledFavoriteContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 16px;
`

const StyledFavorite = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

const StyledFavoriteButtonLabel = styled.span`
  border-bottom: 1px solid rgba(76, 76, 76, 1);
  border-radius: 0;
  font-size: 14px;
  font-weight: 500;
  color: rgba(76, 76, 76, 1);
`

const StyledInputRow = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;

  @media (min-width: 430px) {
    flex-direction: row;
    align-items: flex-end;
  }
`

const StyledInputContainer = styled.div`
  flex: 1;
`
const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 12px;
`

const StyledNotice = styled.div`
  margin-top: 40px;
  margin-bottom: 16px;
  color: var(--primary);
`
