/**
  a helper to transform an amount of minutes into a hours:minutes string.

  @param totalMinutes  - minutes as a number
  @return - returns a hours:minutes string
  example:
  input: 15
  result: "00:15"

 */

export default (totalMinutes: number) => {
  if (!totalMinutes) return '00:00'

  const minutes = (Math.abs(totalMinutes) % 60).toString().padStart(2, '0')
  const hours = Math.floor(Math.abs(totalMinutes) / 60).toString()
  const detectSign = Math.sign(totalMinutes)

  return detectSign === -1 ? `-${hours}:${minutes}` : `${hours.padStart(2, '0')}:${minutes}`
}
