import React, { useMemo } from 'react'
import styled from 'styled-components'

import { InputType } from '../templates/BookingMask'
import { StyledMeetingTime, StyledMeetingTitle, StyledMeetingHeader } from './Meeting'
import Button from './Button'
import { dateToTime } from '../utils'

export interface RecommendedEventProps {
  meetingData: InputType
  onAccept: (id: string) => void
  isInactive?: boolean
}

const RecommendedEvent: React.FC<RecommendedEventProps> = ({ meetingData, onAccept, isInactive }) => {
  const { id, duration, eventName } = meetingData

  const displayedTime = useMemo(() => {
    const { displayedTime } = dateToTime(duration)
    return displayedTime
  }, [duration])

  return (
    <>
      <StyledMeetingHeader>
        <StyledMeetingTitle>{eventName}</StyledMeetingTitle>
        <StyledMeetingTime>{displayedTime}</StyledMeetingTime>
      </StyledMeetingHeader>
      <StyledSubtitle>Termin</StyledSubtitle>
      <StyledButtonContainer>
        <Button
          style={isInactive ? { color: 'var(--text-primary-20)', padding: 0 } : { color: 'var(--primary)', padding: 0 }}
          variant="text"
          onClick={() => onAccept(id)}
          disabled={isInactive}
          dataCy="create.event.booking"
        >
          buchung annehmen
        </Button>
      </StyledButtonContainer>
    </>
  )
}

export default RecommendedEvent

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`

const StyledSubtitle = styled.span`
  opacity: 0.4;
  font-size: 14px;
  line-height: 22px;
`
