/**
  formats the passed date to a day.month.year string

  @param date  - Date instance or null
  @return - returns a day.month.year string or null
  example:
  input: "Wed Sep 28 2022 14:37:05 GMT+0200 (Mitteleuropäische Sommerzeit)"
  result: "28-09-2022"

 */

export default (date: Date | null) => {
  if (!date || isNaN(date.getHours())) return null

  const year = `${date.getFullYear()}`
  const month = `${date.getMonth() + 1}`.padStart(2, '0')
  const day = `${date.getDate()}`.padStart(2, '0')

  return `${day}.${month}.${year}`
}
