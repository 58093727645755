/**
  formats the passed timestring provided by the BFF to a valid Date

  @param time  - time as a string
  @return - returns a Date instance
  example:
  input: "10:30:00+02:00"
  result: "Wed Sep 28 2022 10:30:00 GMT+0200 (Mitteleuropäische Sommerzeit)"

 */

export default (time: string) => {
  if (!time) return null

  const dateString = new Date().toDateString()
  const fixedTimeString = time.split('+').join(' +')
  const finalDate = new Date(`${dateString} ${fixedTimeString}`)
  return finalDate
}
