import { Menu as MUIMenu, MenuItem } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import Dialog from './Dialog'

interface MenuProps {
  anchorEl: null | HTMLElement
  isMenuOpen: boolean
  onClose: () => void
  onAccept: () => void
  handleLogoutDialog: {
    isLogoutDialogOpen: boolean
    setIsLogoutDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
  }
}

const UserMenu: React.FC<MenuProps> = ({ anchorEl, isMenuOpen, onClose, onAccept, handleLogoutDialog }) => {
  const { isLogoutDialogOpen, setIsLogoutDialogOpen } = handleLogoutDialog

  return (
    <>
      <Dialog
        open={isLogoutDialogOpen}
        title="Möchtest du dich abmelden?"
        description={`BenutzerIn abmelden`}
        acceptButtonTitle="abmelden"
        onAccept={onAccept}
        onDecline={() => setIsLogoutDialogOpen(false)}
        declineButtonTitle="Abbrechen"
      />
      <StyledContainer anchorEl={anchorEl} open={isMenuOpen} onClose={onClose}>
        <MenuItem onClick={() => setIsLogoutDialogOpen(true)}>Nutzer abmelden</MenuItem>
        <MenuItem onClick={onClose} component={'a'} href="https://bcs.dasburo.com/" target="_blank">
          Zum BCS wechseln
        </MenuItem>
      </StyledContainer>
    </>
  )
}

// override MUI-MENU_ITEM styles
const StyledContainer = styled(MUIMenu)`
  &&& .MuiList-root.MuiList-padding.MuiMenu-list {
    background: var(--background);
    color: white;
    border: 2px solid white;
    border-radius: 0;

    & li {
      border-bottom: 1px solid var(--white);
    }
  }
`

export default UserMenu
