/**
  a helper to extract all relevant information from a ticket name field and detects if the ticket is a bcs or jira ticket

  @param fullTicketInformation  - a string or undefined
  @return - returns an Object with the ticketName as a string, ticketNumber as a string and a isTicketId boolean
  example:
  input: "#119224 BADMA-307: Alumni - Marco Wagner"
  result: {ticketName: "Alumni - Marco Wagner" , ticketNumber: "BADMA-307", isTicketId: false  }

 */

export default (fullTicketInformation: string | undefined) => {
  if (!fullTicketInformation) return { ticketName: '', ticketNumber: '' }

  const splitByColon = fullTicketInformation.split(':')
  if (splitByColon.length >= 2) {
    const splittedTitle = splitByColon?.[0].split(' ')
    const ticketName = splitByColon[1]
    const ticketNumber = splittedTitle[1]
    return {
      ticketName,
      ticketNumber,
      isTicketId: false,
    }
  } else {
    const splittedTitle = splitByColon?.[0].split(' ')
    const ticketName = splittedTitle[1]
    const ticketNumber = splittedTitle[0]

    return {
      ticketName,
      ticketNumber,
      isTicketId: true,
    }
  }
}
