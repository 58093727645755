import React from 'react'
import styled from 'styled-components'
import DatePicker from '../components/DatePicker'

import { TextField, Typography } from '@mui/material'
import { formatDateToLabel } from '../utils'

type DateValue = Date | null
type DateOnchange = (date: Date | null, keyboardInputValue?: string | undefined) => void

interface DailyOverviewHeaderProps {
  date: DateValue
  setDate: DateOnchange
  totalBookingTime: string
  isClosureDate: boolean | null
}

const DailyOverviewHeader: React.FC<DailyOverviewHeaderProps> = ({
  date,
  setDate,
  totalBookingTime,
  isClosureDate,
}) => {
  return (
    <StyledMeetingCardHeader>
      {isClosureDate ? (
        <StyledTotalBookingTimeLabel
          data-cy="booking.day.total.time"
          label="Datum"
          value={formatDateToLabel(date)}
          onChange={() => {}}
          disabled
          variant="standard"
        />
      ) : (
        <>
          <DatePicker
            label="Datum"
            value={date}
            onChange={setDate}
            fullWidth={false}
            maxWidth="120px"
            minWidth="110px"
            dataCy="booking.date.picker"
          />
          <StyledHeadline variant="body1">Tagesübersicht</StyledHeadline>
        </>
      )}

      <StyledTotalBookingTimeContainer>
        <StyledTotalBookingTimeLabel
          data-cy="booking.day.total.time"
          label="Summe gebucht"
          value={totalBookingTime}
          onChange={() => {}}
          disabled
          variant="standard"
        />
      </StyledTotalBookingTimeContainer>
    </StyledMeetingCardHeader>
  )
}

export default DailyOverviewHeader

const StyledMeetingCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  position: sticky;
  height: 44px;
  padding: 12px 24px;
  box-shadow: var(--elevation-meetingcard-header-bottom);
`
const StyledTotalBookingTimeContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  max-width: 90px;
`

const StyledHeadline = styled(Typography)`
  font-weight: 600;
  display: none;
  @media (min-width: 415px) {
    display: block;
  }
`
// just for styling purposes the textfield is used, to have exact the same alignment as the datepicker
const StyledTotalBookingTimeLabel = styled(TextField)`
  &&& label {
    color: rgba(0, 0, 0, 0.6);
  }

  &&& ::before {
    border-bottom-style: none;
  }
  && input {
    -webkit-text-fill-color: black;
  }
`
