import React, { useEffect, useState } from 'react'
import {
  Autocomplete,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
} from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { BookingTask } from '../templates/BookingMask'

import Button from './Button'

interface FavoritesDialogProps {
  isDialogOpen: boolean
  onAccept: (favorites: BookingTask[]) => void
  onDecline: () => void
  favoriteOptions: BookingTask[]
  favorites: BookingTask[]
}

const FavoritesDialog: React.FC<FavoritesDialogProps> = ({
  isDialogOpen,
  onAccept,
  onDecline,
  favoriteOptions = [],
  favorites = [],
}) => {
  const [curFavorites, setFavorites] = useState<BookingTask[]>(favorites)

  useEffect(() => {
    setFavorites(favorites)
  }, [favorites])

  const onClose = () => {
    setFavorites(favorites)
    onDecline()
  }

  return (
    <Dialog PaperProps={{ sx: { minWidth: '70%', minHeight: '400px' } }} open={isDialogOpen} onClose={onClose}>
      <DialogTitle>{'Favoriten verwalten'}</DialogTitle>
      <DialogContent style={{ paddingTop: '20px' }}>
        <Autocomplete
          multiple
          disableCloseOnSelect
          loading={!!curFavorites}
          loadingText="Lade Buchungspunkte..."
          value={curFavorites}
          onChange={(_, newValue: BookingTask[]) => {
            console.log('onChange() ', newValue)
            setFavorites(newValue)
          }}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          options={favoriteOptions}
          getOptionLabel={(option) => option.label}
          renderOption={(props, option, { selected }) => {
            return (
              <li {...props}>
                <Checkbox
                  inputProps={{ 'aria-label': 'controlled' }}
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </li>
            )
          }}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              // eslint-disable-next-line react/jsx-key
              <Tooltip title={option.label} key={option.value}>
                <Chip label={option.label.split('>').pop()} {...getTagProps({ index })} />
              </Tooltip>
            ))
          }
          renderInput={(params) => <TextField {...params} label="Favoriten" />}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            onClose()
          }}
        >
          Abbrechen
        </Button>
        <Button
          variant="text"
          onClick={() => {
            onAccept(curFavorites)
          }}
        >
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FavoritesDialog
