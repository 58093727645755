/**
  a helper to get the last day of a previous month as a date and an string

  @param date  - Date instance or null
  @param monthOffset  - Offset for month to get the last day of different dates
  @return - returns  an Object with the last day of the previous month as date and as a string
  example:
  input: "Wed Sep 28 2022 14:37:05 GMT+0200 (Mitteleuropäische Sommerzeit)"
  result: {asDate: Wed Aug 31 2022 00:00:00 GMT+0200 (Mitteleuropäische Sommerzeit), asString: "31.08.2022"}

 */

import formatDateToLabel from './formatDateToLabel'

export default (date: Date | null, monthOffset = 0) => {
  if (!date || isNaN(date.getHours())) return null

  const newDate = new Date(date)
  const lastDayOfMonth = new Date(newDate.getFullYear(), newDate.getMonth() + monthOffset, 0)

  const formattedDate = formatDateToLabel(lastDayOfMonth)

  return {
    asDate: lastDayOfMonth,
    asString: formattedDate,
  }
}
