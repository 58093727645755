/**
  adds or subtracts amount of years to the passed date.

  @param date - Date instance
  @param years - a number for the amount of years subtracted or added, e.g -> 2
  @param operation - a string which is either "add" or "subtract" for the operation on the date
  @return - a Date instance with added or subtracted Date
  example:
  input: date = ""2022-09-28T12:09:26.183Z"", years = 2, operation = 'subtract'
  result: "2020-09-27T22:00:00.000Z"
 */

export default (date: Date, years: number, operation: 'add' | 'subtract') => {
  const day = date.getDate()
  const month = date.getMonth()
  const year = date.getFullYear()

  if (operation === 'add') {
    const dateWithAddedYears = new Date(year + years, month, day)

    return dateWithAddedYears
  }

  const dateWithSubtractedYears = new Date(year - years, month, day)

  return dateWithSubtractedYears
}
