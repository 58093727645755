/**
  a helper to evaluate the duration between 2 dates. Needed for the recommended events which have no duration by default and just a start and end time

  @param startTimestring  - startTimestring as a string or null
  @param endTimestring  - endTimestring as a string or null
  @return - returns a number with the duration between the dates. The default is 0
  example:
  input:
  startTimeString: "10:30:00+02:00"
  endTimestring: " 10:45:00+02:00"
  result: 15

 */

import timestringToDate from './timestringToDate'

export default (startTimestring: string | null, endTimestring: string | null): number => {
  if (!startTimestring || !endTimestring) {
    return 0
  }

  const startTimeFormatted = timestringToDate(startTimestring)
  const endTimeFormatted = timestringToDate(endTimestring)

  const hours =
    startTimeFormatted && endTimeFormatted
      ? Math.abs(startTimeFormatted.getTime() - endTimeFormatted.getTime()) / 36e5
      : 0
  const minutes = hours * 60

  return minutes
}
