import React from 'react'

import TextField from '@mui/material/TextField'
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import de from 'date-fns/locale/de'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

interface DatePickerProps {
  label?: string
  value: Date | null
  onChange: (date: Date | null, keyboardInputValue?: string | undefined) => void
  maxWidth?: string
  minWidth?: string
  fullWidth?: boolean
  dataCy?: string
}

const DatePicker: React.FC<DatePickerProps> = ({
  label,
  value,
  onChange,
  maxWidth,
  minWidth,
  fullWidth = true,
  dataCy,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
      <div>
        <MUIDatePicker
          label={label}
          mask={'__.__.____'}
          value={value}
          onChange={onChange}
          renderInput={(params) => (
            <TextField
              style={
                fullWidth
                  ? { width: '100%' }
                  : {
                      maxWidth,
                      minWidth,
                    }
              }
              variant="standard"
              data-cy={dataCy}
              {...params}
            />
          )}
        />
      </div>
    </LocalizationProvider>
  )
}
export default DatePicker
