import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { isLoggedIn } from '../utils/auth'

interface PrivateRouteProps extends RouteComponentProps {
  // ToDo: Find correct generic for ComponentType
  component: React.ComponentType<unknown>
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, location, ...rest }) => {
  const isBrowser = typeof window !== `undefined`
  if (!isBrowser) return null
  if (!isLoggedIn() && location && location.pathname !== `/`) {
    navigate('/')
    return null
  }

  return <Component {...rest} />
}

export default PrivateRoute
