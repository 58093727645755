import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'

import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Card, CardContent, IconButton } from '@mui/material'
import Typography from '@mui/material/Typography'

import { InputType } from '../templates/BookingMask'
import RecommendedEvent from './RecommendedEvent'
import RecommendedTicket from './RecommendedTicket'
import { dateToTime, splitTicketInformation } from '../utils'

export interface MeetingProps {
  meetingData: InputType
  onAccept?: (id: string) => void
  onEdit?: (id: string) => void
  onDelete?: (id: string) => void
  isEditOpen?: boolean
  isInactive?: boolean
  isClosureDate: boolean | null
  dataCy?: string
  duplicateBooking?: (id: string) => void
  isDuplicateBookingOpen?: boolean
}

const Meeting: React.FC<MeetingProps> = ({
  meetingData,
  onAccept,
  onEdit,
  onDelete,
  isEditOpen: editOpen,
  isDuplicateBookingOpen: duplicateOpen,
  isInactive,
  isClosureDate,
  dataCy,
  duplicateBooking,
}) => {
  const { description, duration, id, task, isEvent, isTicket, eventName } = meetingData

  const [isExpanded, setIsExpanded] = useState(false)
  const [isEditOpen, setIsEditOpen] = useState(false)
  const [isDuplicateOpen, setIsDuplicateOpen] = useState(false)

  const isDisabled = Boolean(isEditOpen || isDuplicateOpen || isInactive)

  const [descriptionContainerHeight, setDescriptionContainerHeight] = useState(24)
  const descriptionContainerRef = useRef<HTMLDivElement>(null)
  const descriptionHasMultipleLines = descriptionContainerRef?.current?.clientHeight || 24

  useEffect(() => {
    if (!editOpen) {
      setIsEditOpen(false)
    }
    if (!duplicateOpen) {
      setIsDuplicateOpen(false)
    }
  }, [editOpen, duplicateOpen])

  const handleEdit = (id: string) => {
    setIsEditOpen(!isEditOpen)
    if (onEdit) onEdit(id)
  }

  const handleDuplicateBooking = (id: string) => {
    setIsDuplicateOpen(!isDuplicateOpen)
    if (duplicateBooking) duplicateBooking(id)
  }

  const toggleExpandedContent = () => {
    setIsExpanded(!isExpanded)
  }

  const displayedTime = useMemo(() => {
    const { displayedTime } = dateToTime(duration)
    return displayedTime
  }, [duration])

  const { ticketName, ticketNumber, isTicketId } = useMemo(
    () => splitTicketInformation(meetingData.ticketNr),
    [meetingData.ticketNr],
  )
  const displayedTitle = useMemo(() => {
    if (meetingData.bookingType === 'task') return task?.split('>').pop()
    if (meetingData.bookingType === 'event') return eventName
    return `${ticketNumber} - ${ticketName}`
  }, [meetingData])

  useEffect(() => {
    if (descriptionContainerRef && descriptionContainerRef.current) {
      setDescriptionContainerHeight(descriptionContainerRef.current.clientHeight)
    }
  })

  if (isEvent) {
    return (
      <StyledMeetingContainer
        data-inactive={!!isInactive}
        data-cy={dataCy}
        style={{ backgroundColor: 'var(--secondary)' }}
      >
        <StyledCardContent>
          <RecommendedEvent
            meetingData={meetingData}
            isInactive={isInactive}
            onAccept={() => onAccept && onAccept(id)}
          />
        </StyledCardContent>
      </StyledMeetingContainer>
    )
  }

  if (isTicket) {
    return (
      <StyledMeetingContainer
        data-edit={isEditOpen}
        data-inactive={!!isInactive}
        data-cy={dataCy}
        style={{ backgroundColor: 'var(--secondary)' }}
      >
        <StyledCardContent>
          <RecommendedTicket
            ticketTitle={displayedTitle as string}
            isInactive={isInactive}
            isEditOpen={editOpen}
            isTicketId={isTicketId}
            onEdit={() => handleEdit(id)}
          />
        </StyledCardContent>
      </StyledMeetingContainer>
    )
  }

  return (
    <StyledMeetingContainer data-edit={isEditOpen} data-inactive={!!isInactive} data-cy={dataCy}>
      <StyledCardContent>
        <StyledMeetingHeader>
          <StyledMeetingTitle data-ticketid={isTicketId} style={isEditOpen ? { color: 'var(--primary)' } : {}}>
            {displayedTitle}
          </StyledMeetingTitle>
          <StyledMeetingTime data-cy="booking.card.duration">{displayedTime}</StyledMeetingTime>
        </StyledMeetingHeader>
        <StyledMeetingContent>
          <StyledDescriptionContainer
            data-expanded={!isClosureDate ? isExpanded : true}
            style={{
              height: isExpanded || (isClosureDate && description) ? `${descriptionContainerHeight}px` : '24px',
            }}
          >
            <Typography data-cy="booking.card.description" variant="caption">
              {description && description !== displayedTitle ? description : meetingData.ticketNr }
            </Typography>
          </StyledDescriptionContainer>
          <div style={{ position: 'relative' }}>
            <SytledHiddenDescriptionContainer ref={descriptionContainerRef}>
              <Typography variant="caption">{description}</Typography>
            </SytledHiddenDescriptionContainer>
          </div>
        </StyledMeetingContent>
        {!isClosureDate && (
          <StyledIconContainer>
            <StyledControlIconsContainer>
              <IconButton disabled={isDisabled} onClick={() => handleEdit(id)} style={StyledMeetingButton}>
                <EditOutlinedIcon style={isEditOpen ? { color: 'var(--primary)' } : {}} />
              </IconButton>
              {/* Event cards cannot be duplicated */}
              {meetingData.bookingType !== 'event' && (
                <IconButton
                  disabled={isDisabled}
                  onClick={() => handleDuplicateBooking(id)}
                  style={StyledMeetingButton}
                >
                  <ContentCopyIcon style={isDuplicateOpen ? { color: 'var(--primary)' } : {}} />
                </IconButton>
              )}
              <IconButton
                disabled={isDisabled}
                size="small"
                onClick={() => onDelete && onDelete(id)}
                style={StyledMeetingButton}
              >
                <DeleteOutlinedIcon />
              </IconButton>
            </StyledControlIconsContainer>
            {description && descriptionHasMultipleLines > 24 && (
              <IconButton onClick={toggleExpandedContent} style={StyledMeetingButton}>
                <StyledIcon data-expanded={isExpanded} />
              </IconButton>
            )}
          </StyledIconContainer>
        )}
      </StyledCardContent>
    </StyledMeetingContainer>
  )
}

export default Meeting

const fadeInAnimation = keyframes`
 0% { opacity: 0 }
 100% { opacity: 1 }
`

const StyledIcon = styled(ExpandMoreIcon)<{ 'data-expanded': boolean }>`
  transform: ${(props) => (!props['data-expanded'] ? 'rotate(0deg)' : 'rotate(180deg)')};
`
export const StyledMeetingContainer = styled(Card)<{ 'data-edit'?: boolean; 'data-inactive'?: boolean }>`
  &&& {
    box-shadow: var(--mini-dropdshadow);
    border-radius: 4px;
    margin: 8px 0;
    min-width: 300px;
    box-sizing: border-box;
    animation: ${fadeInAnimation} 500ms ease-in;
    ${(props) => (props['data-edit'] ? 'border: 1px solid var(--primary); box-shadow: var(--elevation-basic)' : '')};
    ${(props) =>
      props['data-inactive']
        ? 'color: var(--text-primary-20); box-shadow: none; background-color: var(--secondary)'
        : ''};
    button {
      ${(props) => (props['data-inactive'] ? 'color: var(--text-primary-20);' : '')};
    }
  }
`

export const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  gap: 4px;
  // in order to override mui cardcontent default padding
  &&& {
    padding: 4px 16px 8px;
  }
`

export const StyledMeetingHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`

//ToDo: Redefine font styles and use them
export const StyledMeetingTitle = styled.span<{ 'data-ticketid'?: boolean }>`
  font-weight: 600;
  font-size: 14px;
  line-height: 32px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => (props['data-ticketid'] ? 'font-style: italic' : '')}
`
export const StyledMeetingTime = styled.span`
  font-size: 14px;
  line-height: 32px;
`

const StyledMeetingContent = styled.div``

const StyledDescriptionContainer = styled.div<{ 'data-expanded': boolean }>`
  white-space: ${(props) => (props['data-expanded'] ? 'normal' : 'nowrap')};
  overflow: ${(props) => (props['data-expanded'] ? 'unset' : 'hidden')};
  text-overflow: ${(props) => (props['data-expanded'] ? 'unset' : 'ellipsis')};
  width: ${(props) => (props['data-expanded'] ? ' 100%' : 'calc(100% - 34px)')};
  transition: height 100ms;
`

const SytledHiddenDescriptionContainer = styled.div`
  color: transparent;
  opacity: 0;
  position: absolute;
  z-index: -100;
  width: 100%;
  top: 0;
`

const StyledIconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`

export const StyledControlIconsContainer = styled.div`
  display: flex;
  gap: 8px;
`

export const StyledMeetingButton = {
  padding: 0,
}
