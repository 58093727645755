import React from 'react'
import { Router } from '@reach/router'

import BookingSystem from '../templates/BookingSystem'
import PrivateRoute from '../components/PrivateRoute'

const App = () => {
  return (
    <Router>
      <PrivateRoute path="app" component={BookingSystem} />
    </Router>
  )
}

export default App
