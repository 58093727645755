import React, { useEffect, useMemo } from 'react'

import { IconButton, Typography } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'

import { dateToDayTime, dateToLabel } from '../utils'

interface OpenerProps {
  date: Date
  handleArrowDateChange: (direction: 'nextDay' | 'previousDay', keyboardInputValue?: string | undefined) => void
}

const Opener: React.FC<OpenerProps> = ({ date, handleArrowDateChange }) => {
  const changeCustomDay = (e: KeyboardEvent) => {
    // usually keyboard events are not triggered from DOM elements, so we check here if that was that case
    if (e.target instanceof Element) {
      const eventIsTriggeredByUnwantedElement = ['INPUT', 'TEXTAREA'].includes(e.target.nodeName)
      if (eventIsTriggeredByUnwantedElement) {
        return
      }
    }

    if (e.key === 'ArrowRight') {
      handleArrowDateChange('nextDay')
    } else if (e.key === 'ArrowLeft') {
      handleArrowDateChange('previousDay')
    } else {
      return
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', changeCustomDay)

    return () => document.removeEventListener('keydown', changeCustomDay)
  }, [date])

  const formattedDate = useMemo(() => {
    const openerTitle = dateToDayTime()
    const openerSubTitle = dateToLabel(date)

    return {
      openerTitle,
      openerSubTitle,
    }
  }, [date])

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', margin: 0 }}>
      <IconButton
        edge="start"
        style={{ color: 'white', padding: 0 }}
        onClick={() => handleArrowDateChange('previousDay')}
      >
        <KeyboardArrowLeftIcon style={{ width: '60px', height: '60px' }} />
      </IconButton>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h1">{formattedDate.openerTitle}</Typography>

        <Typography variant="h2">{formattedDate.openerSubTitle}</Typography>
      </div>

      <IconButton
        edge="start"
        style={{ color: 'white', padding: 0, margin: 0 }}
        onClick={() => handleArrowDateChange('nextDay')}
      >
        <KeyboardArrowRightIcon style={{ width: '60px', height: '60px' }} />
      </IconButton>
    </div>
  )
}

export default Opener
