import { Alert, AlertColor, Snackbar } from '@mui/material'
import React, { Key, useEffect } from 'react'
import styled from 'styled-components'
import Button from './Button'

export interface SnackbarMessage {
  message: string
  key: Key
  color?: string
}
export interface NotificationState {
  open: boolean
  snackPack: readonly SnackbarMessage[]
  messageInfo?: SnackbarMessage
}

interface NotificationProps {
  handleMessage: {
    snackPack: readonly SnackbarMessage[]
    setSnackPack: React.Dispatch<React.SetStateAction<readonly SnackbarMessage[]>>
  }
  subTitle?: string
  severity?: AlertColor
  autoHideDuration?: number
  undo?: () => void
}

const Notification: React.FC<NotificationProps> = ({ handleMessage, subTitle, severity, autoHideDuration, undo }) => {
  const { snackPack, setSnackPack } = handleMessage
  const [open, setOpen] = React.useState(false)
  const [messageInfo, setMessageInfo] = React.useState<SnackbarMessage | undefined>(undefined)

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] })
      setSnackPack((prev) => prev.slice(1))
      setOpen(true)
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false)
    }
  }, [snackPack, messageInfo, open])

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const handleUndo = () => {
    if (undo) {
      undo()
      setOpen(false)
    }
  }
  const handleExited = () => {
    setMessageInfo(undefined)
  }

  return (
    <Snackbar
      sx={{ maxWidth: '50px' }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      key={messageInfo ? messageInfo.key : undefined}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
      message={messageInfo ? messageInfo.message : undefined}
    >
      <Alert severity={severity} color="info" style={{ background: `var(--${messageInfo?.color})`, minWidth: '450px' }}>
        <StyledAlertContainer>
          <StyledHeadlineContainer>
            <StyledHeadline data-cy="snackbar.message">{messageInfo?.message}</StyledHeadline>
            {subTitle && <StyledSubTitle>{subTitle}</StyledSubTitle>}
          </StyledHeadlineContainer>
          {undo && (
            <Button style={{ padding: '0' }} color="info" variant="text" onClick={handleUndo}>
              UNDO
            </Button>
          )}
        </StyledAlertContainer>
      </Alert>
    </Snackbar>
  )
}

export default Notification

const StyledAlertContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
`

const StyledHeadlineContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

const StyledHeadline = styled.span`
  color: var(--white);
  font-size: 16px;
  line-height: 24px;
`

const StyledSubTitle = styled.span`
  color: var(--white);
  font-size: 14px;
  line-height: 20px;
`
