/**
  formats the passed date to a year-month-day string

  @param date  - Date instance or null
  @return - returns a year-month-day string
  example:
  input: "Wed Sep 28 2022 14:37:05 GMT+0200 (Mitteleuropäische Sommerzeit)"
  result: "2022-09-28"

 */

export default (date: Date) => {
  const year = `${date.getFullYear()}`
  const month = `${date.getMonth() + 1}`.padStart(2, '0')
  const day = `${date.getDate()}`.padStart(2, '0')
  return `${year}-${month}-${day}`
}
