import React, { SyntheticEvent } from 'react'
import {
  Autocomplete as MUIAutocomplete,
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
  TextField,
} from '@mui/material'
import { Variants } from './TextField'

export interface AutocompleteProps {
  dataCy?: string
  freeSolo?: boolean
  disableClearable?: boolean
  disabled?: boolean
  loading?: boolean
  loadingText?: string
  value: string | null
  inputValue?: string
  onInputChange: (event: SyntheticEvent<Element, Event>, value: string, reason?: AutocompleteInputChangeReason) => void
  onChange?: (event: SyntheticEvent<Element, Event>, value: string | null) => void
  options: string[]
  label?: React.ReactNode
  noOptionsText?: string
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode
  variant?: Variants
  fullWidth?: boolean
  name?: string
  error?: boolean
  helperText?: string
  type?: 'button' | 'search' | 'email' | 'text'
}

const Autocomplete: React.FC<AutocompleteProps> = ({
  dataCy,
  freeSolo = true,
  disableClearable = true,
  disabled,
  loading,
  loadingText,
  value,
  inputValue,
  onInputChange,
  onChange,
  options,
  renderInput,
  label,
  noOptionsText = 'Keine Treffer',
  variant = 'standard',
  fullWidth = true,
  name,
  error,
  helperText,
  type = 'search',
}) => (
  <MUIAutocomplete
    freeSolo={freeSolo}
    disableClearable={disableClearable}
    disabled={disabled}
    loading={loading}
    value={value}
    inputValue={inputValue}
    onInputChange={onInputChange}
    onChange={onChange}
    options={options}
    renderInput={
      renderInput
        ? renderInput
        : (params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                type,
              }}
              label={label}
              name={name}
              error={error}
              helperText={helperText}
              variant={variant}
              fullWidth={fullWidth}
              data-cy={dataCy}
            />
          )
    }
    noOptionsText={noOptionsText}
    loadingText={loadingText}
  />
)

export default Autocomplete
